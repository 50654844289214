import { FaSignInAlt, FaSignOutAlt, FaUser, FaList, FaDashcube, FaBell, FaDollarSign } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { BsFillGearFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout, reset } from "../features/auth/authSlice";
import { getMessages } from "../features/messages/messageSlice";
import { useEffect, useState } from "react";
import btdrplogo from "../images/btdrplogo.png";

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { messages, isLoading, isError, message } = useSelector((state) => state.messages);

  const [isBannerVisible, setBannerVisible] = useState(true); // State to manage the visibility of the banner

  useEffect(() => {
    // Check if the user has dismissed the banner before
    const isBannerVisible = localStorage.getItem("isBannerVisible");
    if (isBannerVisible === "false") {
      setBannerVisible(false);
    }
    // localStorage.setItem("isBannerVisible", true);
    if (isError) {
      console.log(message);
    }

    if (!user) {
      navigate("/login");
      console.log("yeetDash");
    }

    dispatch(getMessages());
  }, [user]);

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    console.log("wtf");
    navigate("/");
  };

  const dismissBanner = () => {
    setBannerVisible(false); // Hide the banner when the X button is clicked
    // You can also store this in localStorage to persist the user's preference
    localStorage.setItem("isBannerVisible", false);
  };

  return (
    <>
      <header className="header" style={{ position: "relative" }}>
        <div className="logo">
          <Link to="/">
            <img
              src={btdrplogo}
              width="140px"
              style={{
                marginTop: "-10px",
                marginBottom: "-20px",
              }}
            />
          </Link>
        </div>
        <ul>
          {user && user.role == "admin" && (
            <>
              <li>
                <Link to="./accounts">
                  <FaList /> Accounts
                </Link>
              </li>
              <li>
                <Link to="./">
                  <FaDashcube /> Dashboard
                </Link>
              </li>
            </>
          )}
          {user && user.role != "admin" && (
            <>
              <li>
                <p
                  style={{ cursor: "pointer", fontSize: "16px" }}
                  onClick={() => {
                    window.open("mailto:support@btdrp.com");
                  }}
                >
                  <AiOutlineMail /> Support
                </p>
              </li>
              <li>
                <Link to="./messages">
                  <FaBell />
                  Messages{" "}
                  {messages && messages.length > 0 && (
                    <span style={{ fontSize: "10px" }}>" 🔴"</span>
                  )}
                </Link>
              </li>
              <li>
                <Link to="./albums">
                  <FaList /> My Albums
                </Link>
              </li>
              <li>
                <Link to="./">
                  <FaDashcube /> Submit an Album
                </Link>
              </li>
            </>
          )}
          {user ? (
            <>
              <li>
                <Link to="./payees">
                  <FaDollarSign /> Payees
                </Link>
              </li>
              <li>
                <Link to="./settings">
                  <BsFillGearFill /> Settings
                </Link>
              </li>
              <li>
                <button className="btn" onClick={onLogout}>
                  <FaSignOutAlt /> Logout
                </button>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link to="./login">
                  <FaSignInAlt /> Login
                </Link>
              </li>
              <li>
                <Link to="./register">
                  <FaUser /> Register
                </Link>
              </li>
            </>
          )}
        </ul>
      </header>

      {/* Rebrand Banner */}
      {isBannerVisible && (
        <div
          style={{
            position: "absolute",
            top: "75px", // Adjust based on the height of your header
            left: "0",
            width: "100%",
            backgroundColor: "#f8d7da", // Light red background
            color: "#721c24", // Dark red text
            padding: "7px",
            textAlign: "center",
            fontSize: "16px",
            zIndex: "999",
          }}
        >
          <span>
            The System Distro is rebranding to Btdrp. The distribution process remains the same.
            <button
              onClick={dismissBanner}
              style={{
                background: "none",
                border: "none",
                fontSize: "20px",
                color: "#721c24",
                cursor: "pointer",
                marginLeft: "10px",
              }}
            >
              ✖
            </button>
          </span>
        </div>
      )}
    </>
  );
}

export default Header;
