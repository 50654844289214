import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSavedArtists,
  createSavedArtist,
  reset,
} from "../features/savedArtists/savedArtistSlice";
import { createSong } from "../features/songs/songSlice";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { FaEdit, FaTrashAlt, FaPlus } from "react-icons/fa";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

function SongEditForm(props) {
  const [songName, setSongName] = useState(props.song.name);
  const [songArtist, setSongArtist] = useState("");
  const [isrc, setIsrc] = useState(props.song.isrc);
  const [previewStartTime, setPreviewStartTime] = useState(
    props.song.previewStartTime
  );

  const [songNumber, setSongNumber] = useState(props.song.songNumber);

  const [audioFile, setAudioFile] = useState(null);

  const [album, setAlbum] = useState(props.song.albumId);

  const [artists, setArtists] = useState(props.song.artists);

  const [writers, setWriters] = useState(props.song.writers);
  const [producers, setProducers] = useState(props.song.producers);

  const [formCompleted, setFormCompleted] = useState(false);

  const [firstRenderDone, setFirstRenderDone] = useState(false);

  //good to go file indicator
  const [fileIndicator, setFileIndicator] = useState(false);

  //error file indicator
  const [errorFileIndicator, setErrorFileIndicator] = useState(false);

  const [payees, setPayees] = useState(
    props.song.payeeInfo?.payees?.length > 0 ? props.song.payeeInfo.payees : []
  );

  const [explicit, setExplicit] = useState(
    props.song.explicit != null ? props.song.explicit : false
  );

  const artistRoleOptions = [
    { value: "Primary", label: "Primary" },
    { value: "Featured", label: "Featured" },
  ];

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { savedArtists, isLoading, isError, message } = useSelector(
    (state) => state.savedArtists
  );

  let artistOptions = savedArtists.map((artist) => ({
    value: artist._id,
    label: artist.artistName,
  }));

  useEffect(() => {
    if (isError) {
      console.log(message);
      //localStorage.clear();
    }

    if (!user) {
      /*setTimeout(() => {
        console.log("World!");
      }, 5000);*/
      //navigate("/login");
      console.log("yeetDash");
    }

    if (user) {
      dispatch(getSavedArtists());
      artistOptions = savedArtists.map((artist) => ({
        value: artist._id,
        label: artist.artistName,
      }));
    }

    return () => {
      dispatch(reset());
    };
  }, [user]);

  /*useImperativeHandle(ref, () => ({
    getFormCompleted: () => formCompleted,
  }));*/

  /*useEffect(() => {
    setFirstRenderDone(true);
  }, []);

  useEffect(() => {
    setAlbum(props.album);
  }, [props.album]);*/

  /*useEffect(() => {
    if (props.trigger) {
      console.log("Triggered: ", songNumber);*/

  // get secure url from our server
  /*const { url } = await fetch("/s3Url").then((res) => res.json());
      console.log(url);

      // post the image direclty to the s3 bucket
      await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: audioFile,
      });

      const audioUrl = url.split("?")[0];*/

  //const audioUrl = gets3Url();

  /*  gets3Url();
    }
  }, [props.trigger]);*/

  /*useEffect(() => {
    if (
      firstRenderDone &&
      songName != "" &&
      //songArtist != "" &&
      isrc != "" &&
      previewStartTime != "" &&
      audioFile != null &&
      artists.length > 0 &&
      writers.length > 0
    ) {
      setFormCompleted(true);
    } else {
      setFormCompleted(false);
    }
  }, [songName, songArtist, isrc, audioFile]);

  useEffect(() => {
    if (firstRenderDone) {
      if (formCompleted) {
        props.increment();
      } else {
        props.decrement();
      }
    }
  }, [formCompleted]);*/

  function addWriter() {
    setWriters([
      ...writers,
      {
        writerName: "Writer Name",
      },
    ]);
  }

  function deleteWriter(index) {
    const updatedWriters = [...writers];
    updatedWriters.splice(index, 1);
    setWriters(updatedWriters);
  }

  function handleWriterNameChange(e, index) {
    let updatedWriters = [...writers];
    updatedWriters[index] = {
      ...updatedWriters[index],
      writerName: e.target.value,
    };
    setWriters(updatedWriters);
  }

  function addProducer() {
    setProducers([
      ...producers,
      {
        producerName: "Producer Name",
      },
    ]);
  }

  function deleteProducer(index) {
    const updatedProducers = [...producers];
    updatedProducers.splice(index, 1);
    setProducers(updatedProducers);
  }

  function handleProducerNameChange(e, index) {
    const updatedProducers = [...producers];
    updatedProducers[index].producerName = e.target.value;
    setProducers(updatedProducers);
  }

  function addArtist() {
    setArtists([
      ...artists,
      {
        artistName: "Artist Name",
        spotifyURI: "New Artist",
        appleId: "New Artist",
        role: "primary",
      },
    ]);
  }

  function deleteArtist(index) {
    const updatedArtists = [...artists];
    updatedArtists.splice(index, 1);
    setArtists(updatedArtists);
  }

  function handleArtistNameChange(e, index) {
    // const updatedArtists = [...artists];
    // updatedArtists[index].artistName = e.target.value;
    // setArtists(updatedArtists);
    const updatedArtists = [...artists];
    updatedArtists[index] = {
      ...updatedArtists[index],
      artistName: e.target.value,
    };
    setArtists(updatedArtists);
  }

  function handleSpotifyURIChange(e, index) {
    // const updatedArtists = [...artists];
    // updatedArtists[index].spotifyURI = e.target.value;
    // setArtists(updatedArtists);
    const updatedArtists = [...artists];
    updatedArtists[index] = {
      ...updatedArtists[index],
      spotifyURI: e.target.value,
    };
    setArtists(updatedArtists);
  }

  function handleAppleIdChange(e, index) {
    // const updatedArtists = [...artists];
    // updatedArtists[index].appleId = e.target.value;
    // setArtists(updatedArtists);
    const updatedArtists = [...artists];
    updatedArtists[index] = {
      ...updatedArtists[index],
      appleId: e.target.value,
    };
    setArtists(updatedArtists);
  }

  function handleRoleChange(selectedOption, index) {
    const updatedArtists = [...artists];
    updatedArtists[index] = {
      ...updatedArtists[index],
      role: selectedOption.value,
    };
    setArtists(updatedArtists);
  }

  function handleArtistSelect(selectedOption, index) {
    const filteredArray = savedArtists.filter((obj) => {
      return obj._id.toString() == selectedOption.value;
    });
    const updatedArtists = [...artists];
    updatedArtists[index].artistName = filteredArray[0].artistName;
    updatedArtists[index].role = filteredArray[0].role;
    updatedArtists[index].spotifyURI = filteredArray[0].spotifyURI;
    updatedArtists[index].appleId = filteredArray[0].appleId;
    setArtists(updatedArtists);
  }

  function saveArtist(art) {
    //const updatedArtists = [...artists];
    //updatedArtists[index].role = selectedOption.value;
    //setArtists(updatedArtists);
    const artistName = art.artistName;
    const role = art.role;
    const spotifyURI = art.spotifyURI;
    const appleId = art.appleId;
    dispatch(
      createSavedArtist({
        artistName,
        role,
        spotifyURI,
        appleId,
      })
    );
    dispatch(reset());
    dispatch(getSavedArtists());
    artistOptions = savedArtists.map((artist) => ({
      value: artist._id,
      label: artist.artistName,
    }));

    toast("Artist info saved!");
  }

  function addPayee() {
    setPayees([
      ...payees,
      {
        name: "",
        email: "",
        percentage: "",
      },
    ]);
  }

  function deletePayee(index) {
    const updatedPayees = [...payees];
    updatedPayees.splice(index, 1);
    setPayees(updatedPayees);
  }

  // function handlePayeeNameChange(e, index) {
  //   const updatedPayees = [...payees];
  //   updatedPayees[index].name = e.target.value;
  //   setPayees(updatedPayees);
  // }

  function handlePayeeNameChange(e, index) {
    const updatedPayees = payees.map((payee, i) =>
      i === index ? { ...payee, name: e.target.value } : payee
    );
    setPayees(updatedPayees);
  }

  // function handlePayeeEmailChange(e, index) {
  //   const updatedPayees = [...payees];
  //   updatedPayees[index].email = e.target.value;
  //   setPayees(updatedPayees);
  // }

  function handlePayeeEmailChange(e, index) {
    const updatedPayees = payees.map((payee, i) =>
      i === index ? { ...payee, email: e.target.value } : payee
    );
    setPayees(updatedPayees);
  }

  // function handlePayeePercentageChange(e, index) {
  //   const updatedPayees = [...payees];
  //   updatedPayees[index].percentage = e.target.value;
  //   setPayees(updatedPayees);
  // }

  function handlePayeePercentageChange(e, index) {
    const updatedPayees = payees.map((payee, i) =>
      i === index ? { ...payee, percentage: e.target.value } : payee
    );
    setPayees(updatedPayees);
  }

  const handleFile = (e) => {
    const file = e.target.files[0];
    const fileType = file.type;
    const validFileTypes = ["audio/wav", "audio/x-wav", "audio/vnd.wave"];
    const validFileType = validFileTypes.includes(fileType);

    if (!validFileType) {
      alert("Please upload a WAV file");
      setErrorFileIndicator(true);
      setFileIndicator(false);
      return;
    }

    setAudioFile(e.target.files[0]);
    setFileIndicator(true);
    setErrorFileIndicator(false);
  };

  const gets3Url = async () => {
    // get secure url from our server
    const { url } = await fetch("/s3Url").then((res) => res.json());
    console.log(url);

    // post the image direclty to the s3 bucket
    await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "Audio/x-wav",
      },
      body: audioFile,
    });

    const audioUrl = url.split("?")[0];

    /*if (previewStartTime == "") {
      setPreviewStartTime("None");
    }*/

    dispatch(
      createSong({
        songName,
        songNumber,
        //songArtist,
        isrc,
        album,
        audioUrl,
        previewStartTime,
        artists,
        writers,
        producers,
        payeeInfo: {
          isEntered: false,
          payees: payees,
        },
        explicit,
      })
    );
  };

  /*const setFileToBase = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setAudioFile(reader.result);
      console.log(reader.result);
    };
  });*/

  const onSubmit = async (e) => {
    e.preventDefault();

    // console.log("made it to onSubmit 1");

    //setAlbumId(Math.random().toString(36).substr(2, 9));
    //console.log(albumId);

    if (
      //firstRenderDone &&
      songName != "" &&
      //songArtist != "" &&
      isrc != "" &&
      previewStartTime != "" &&
      //audioFile != null &&
      artists.length > 0 &&
      writers.length > 0
    ) {
      //setTriggerSongSubmit(triggerSongSubmit + 1);
      // console.log("made it to onSubmit 2");

      // let imageUrl = props.goal.imageUrl;
      let audioUrl = props.song.audioUrl;

      if (audioFile != null) {
        // get secure url from our server
        const { url } = await fetch("/s3Url").then((res) => res.json());
        console.log(url);

        // post the image direclty to the s3 bucket
        await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "Audio/x-wav",
          },
          body: audioFile,
        });

        audioUrl = url.split("?")[0];
      }

      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
          "Content-Type": "application/json",
        },
      };

      // console.log("USER TOKEN: ", user.token);
      // const updatedPayees = payees.map(payee => ({ ...payee }));

      let firstRes = await axios.put(
        "/api/songs/" + props.song._id,
        {
          name: songName,
          songNumber: songNumber,
          isrc: isrc,
          album: album,
          audioUrl: audioUrl,
          previewStartTime: previewStartTime,
          artists: artists,
          writers: writers,
          producers: producers,
          payeeInfo: {
            isEntered: false,
            payees: payees,
          },
          explicit: explicit,
        },
        config
      );

      console.log(firstRes);

      let changeLog = [];

      // make a variable called newStatus that is set to "changes added: " if there are changes to any song fields, then the fields that have been changed separated by commas
      let newStatus = `changes added to song ${songNumber}: `;
      if (songName != props.song.name) {
        newStatus += "song name, ";
        changeLog.push(
          `Song name for song ${songNumber} changed from ${props.song.name} to ${songName}`
        );
      }
      if (isrc != props.song.isrc) {
        newStatus += "isrc, ";
        changeLog.push(
          `ISRC for song ${songNumber} changed from ${props.song.isrc} to ${isrc}`
        );
      }
      if (album != props.song.albumId) {
        newStatus += "album, ";
        changeLog.push(
          `Album for song ${songNumber} changed from ${props.song.albumId} to ${album}`
        );
      }
      if (audioUrl != props.song.audioUrl) {
        newStatus += "audio file, ";
        changeLog.push(`Audio file for song ${songNumber} changed`);
      }
      if (previewStartTime != props.song.previewStartTime) {
        newStatus += "preview start time, ";
        changeLog.push(
          `Preview start time for song ${songNumber} changed from ${props.song.previewStartTime} to ${previewStartTime}`
        );
      }
      if (artists != props.song.artists) {
        newStatus += "artists, ";
        // loop through artists and check if any have changed. If so, note the specific field that changed, as well as the song number
        for (let i = 0; i < artists.length; i++) {
          if (
            artists[i] &&
            props.song.artists[i] &&
            artists[i].artistName != props.song.artists[i].artistName
          ) {
            changeLog.push(
              `Artist name for song ${songNumber} changed from ${props.song.artists[i].artistName} to ${artists[i].artistName}`
            );
          }
          if (
            artists[i] &&
            props.song.artists[i] &&
            artists[i].role != props.song.artists[i].role
          ) {
            changeLog.push(
              `Artist role for song ${songNumber} changed from ${props.song.artists[i].role} to ${artists[i].role}`
            );
          }
          if (
            artists[i] &&
            props.song.artists[i] &&
            artists[i].spotifyURI != props.song.artists[i].spotifyURI
          ) {
            changeLog.push(
              `Spotify URI for song ${songNumber} changed from ${props.song.artists[i].spotifyURI} to ${artists[i].spotifyURI}`
            );
          }
          if (
            artists[i] &&
            props.song.artists[i] &&
            artists[i].appleId != props.song.artists[i].appleId
          ) {
            changeLog.push(
              `Apple ID for song ${songNumber} changed from ${props.song.artists[i].appleId} to ${artists[i].appleId}`
            );
          }
        }
      }
      if (writers != props.song.writers) {
        newStatus += "writers, ";
        // loop through writers and check if any have changed. If so, note the specific field that changed, as well as the song number
        for (let i = 0; i < writers.length; i++) {
          if (
            writers[i] &&
            props.song.writers[i] &&
            writers[i].writerName != props.song.writers[i].writerName
          ) {
            changeLog.push(
              `Writer name for song ${songNumber} changed from ${props.song.writers[i].writerName} to ${writers[i].writerName}`
            );
          }
        }
      }
      if (producers != props.song.producers) {
        newStatus += "producers, ";
        // loop through producers and check if any have changed. If so, note the specific field that changed, as well as the song number
        for (let i = 0; i < producers.length; i++) {
          if (
            producers[i] &&
            props.song.producers[i] &&
            producers[i].producerName != props.song.producers[i].producerName
          ) {
            changeLog.push(
              `Producer name for song ${songNumber} changed from ${props.song.producers[i].producerName} to ${producers[i].producerName}`
            );
          }
        }
      }
      if (
        (props.song.payeeInfo?.payees &&
          JSON.stringify(payees) !=
            JSON.stringify(props.song.payeeInfo.payees)) ||
        (!props.song.payeeInfo?.payees && payees.length > 0)
      ) {
        console.log("payees: ", payees);
        console.log(
          "props.song.payeeInfo.payees: ",
          props.song.payeeInfo.payees
        );
        newStatus += "payees, ";
        // loop through payees and check if any have changed. If so, note the specific field that changed, as well as the song number
        for (let i = 0; i < payees.length; i++) {
          console.log("payee: ", payees[i]);
          if (
            payees[i].name !=
              (props.song.payeeInfo?.payees[i]
                ? props.song.payeeInfo.payees[i].name
                : "") ||
            payees[i].email !=
              (props.song.payeeInfo?.payees[i]
                ? props.song.payeeInfo.payees[i].email
                : "") ||
            payees[i].percentage !=
              (props.song.payeeInfo?.payees[i]
                ? props.song.payeeInfo.payees[i].percentage
                : "")
          ) {
            changeLog.push(`Payee ${i + 1} for song ${songNumber} changed`);
          }
        }
      }

      if (props.song.explicit != null && explicit != props.song.explicit) {
        newStatus += "explicit, ";
        changeLog.push(
          `Explicit for song ${songNumber} changed from ${props.song.explicit} to ${explicit}`
        );
      } else if (props.song.explicit == null && explicit) {
        newStatus += "explicit, ";
        changeLog.push(
          `Explicit for song ${songNumber} changed from false to true`
        );
      }

      newStatus = newStatus.slice(0, -2);

      if (
        !props.goal.isDistributed ||
        props.goal.isDistributed == false
      ) {
        newStatus = "pending approval by team";
      }

      let payeeOnlyChanges = true;
      for(let i = 0; i < changeLog.length; i++) {
        if(!changeLog[i].includes("Payee")) {
          payeeOnlyChanges = false;
          break;
        }
      }

      // if newStatus is not equal to "changes added to song 1: " then make a put request to the goal with the newStatus
      if (newStatus != `changes added to song ${songNumber}: `) {
        await axios.put(
          "/api/goals/" + props.goal._id,
          {
            status: !payeeOnlyChanges ? newStatus : props.goal.status,
            changeLog: props.goal.changeLog
              ? [...props.goal.changeLog, ...changeLog]
              : changeLog,
          },
          config
        );
      }

      toast(
        "Album sent for review. You can view your album status by clicking on My Albums"
      );
      window.location.reload(false);
    } else {
      toast("One or more song fields left empty.");
    }
  };

  return (
    <div
      style={{
        overflow: "scroll",
        height: "100vh",
        // marginBottom: "200px",
      }}
    >
      <ToastContainer />
      <section className="form">
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="text">
              Song Name <span style={{ color: "#bd0000" }}>*</span>
            </label>
            <input
              type="text"
              name="text"
              id="text"
              value={songName}
              onChange={(e) => {
                setSongName(e.target.value);
              }}
            />
            <label htmlFor="text">
              Artist(s) <span style={{ color: "#bd0000" }}>*</span>
            </label>
            {artists.map((art, index) => (
              <div
                key={index}
                className="goal"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <label>{art.artistName}</label>
                <div>
                  <Popup
                    nested
                    trigger={
                      <button
                        type="button"
                        style={{
                          fontSize: "20px",
                          background: "none",
                          border: "none",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        <FaEdit />
                      </button>
                    }
                    position="center"
                    className="my-popup"
                    modal
                  >
                    <div className="form-group">
                      {savedArtists && savedArtists.length > 0 && (
                        <div>
                          <label>Select saved artist:</label>
                          <Select
                            defaultValue={null}
                            onChange={(selected) => {
                              handleArtistSelect(selected, index);
                            }}
                            options={artistOptions}
                          />
                        </div>
                      )}
                      <label htmlFor="text">
                        Role <span style={{ color: "#bd0000" }}>*</span>
                      </label>
                      <Select
                        defaultValue={art.role}
                        onChange={(selectedValue) => {
                          handleRoleChange(selectedValue, index);
                        }}
                        options={artistRoleOptions}
                      />
                      <div style={{ paddingBottom: "20px" }}></div>
                      <label>
                        Artist Name: <span style={{ color: "#bd0000" }}>*</span>
                      </label>
                      <input
                        type="text"
                        value={art.artistName}
                        onChange={(e) => handleArtistNameChange(e, index)}
                      />

                      <label>
                        Spotify URI (e.g.
                        spotify:artist:6kw4tIoctSJkUveo6rkW9u):
                        {art.spotifyURI != "New Artist" &&
                          art.spotifyURI.length != 37 && (
                            <>
                              <br />
                              <span style={{ color: "red" }}>
                                Please enter a valid Spotify URI or "New Artist"
                              </span>
                            </>
                          )}
                      </label>
                      <input
                        type="text"
                        value={art.spotifyURI}
                        onChange={(e) => handleSpotifyURIChange(e, index)}
                      />
                      <label>Apple Id (e.g. 1523332292):</label>
                      <input
                        type="text"
                        value={art.appleId}
                        onChange={(e) => handleAppleIdChange(e, index)}
                      />
                    </div>
                    <button
                      type="button"
                      className="chill-btn"
                      onClick={() => saveArtist(art)}
                    >
                      Save Artist
                    </button>
                  </Popup>
                  <button
                    type="button"
                    onClick={() => deleteArtist(index)}
                    style={{
                      fontSize: "20px",
                      background: "none",
                      border: "none",
                      color: "#bd0000",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <FaTrashAlt />
                  </button>
                </div>
              </div>
            ))}
            <button type="button" className="chill-btn" onClick={addArtist}>
              Add Artist <FaPlus />
            </button>
            <label htmlFor="text">
              Writer(s) <span style={{ color: "#bd0000" }}>*</span>
            </label>
            {writers.map((writer, index) => (
              <div
                key={index}
                //className="goal"
                //style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <label>
                    Writer {index + 1}{" "}
                    <button
                      type="button"
                      onClick={() => deleteWriter(index)}
                      style={{
                        fontSize: "20px",
                        background: "none",
                        border: "none",
                        color: "#bd0000",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <FaTrashAlt />
                    </button>
                  </label>
                  <input
                    type="text"
                    value={writer.writerName}
                    onChange={(e) => handleWriterNameChange(e, index)}
                  />
                </div>
              </div>
            ))}
            <button type="button" className="chill-btn" onClick={addWriter}>
              Add Writer <FaPlus />
            </button>
            <label htmlFor="text">
              Producer(s) <span style={{ color: "#bd0000" }}>*</span>
            </label>
            {producers.map((producer, index) => (
              <div
                key={index}
                //className="goal"
                //style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <label>
                    Producer {index + 1}{" "}
                    <button
                      type="button"
                      onClick={() => deleteProducer(index)}
                      style={{
                        fontSize: "20px",
                        background: "none",
                        border: "none",
                        color: "#bd0000",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <FaTrashAlt />
                    </button>
                  </label>
                  <input
                    type="text"
                    value={producer.producerName}
                    onChange={(e) => handleProducerNameChange(e, index)}
                  />
                </div>
              </div>
            ))}
            <button type="button" className="chill-btn" onClick={addProducer}>
              Add Producer <FaPlus />
            </button>
            <label htmlFor="text">ISRC (optional) </label>
            <input
              type="text"
              name="text"
              id="text"
              value={isrc}
              onChange={(e) => {
                setIsrc(e.target.value);
              }}
            />
            <label htmlFor="text">
              Start Time for Tiktok e.g. 01:30 (optional)
            </label>
            <input
              type="text"
              name="text"
              id="text"
              value={previewStartTime}
              onChange={(e) => {
                setPreviewStartTime(e.target.value);
              }}
            />
            <h3 htmlFor="text">Payees:</h3>
            {payees.map((payee, index) => (
              <div key={index}>
                <label>
                  Payee {index + 1}{" "}
                  <button
                    type="button"
                    onClick={() => deletePayee(index)}
                    style={{
                      fontSize: "20px",
                      background: "none",
                      border: "none",
                      color: "#bd0000",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <FaTrashAlt />
                  </button>
                </label>
                <input
                  type="text"
                  value={payee.name}
                  placeholder="Enter Payee Full Name..."
                  onChange={(e) => handlePayeeNameChange(e, index)}
                />
                <input
                  type="text"
                  value={payee.email}
                  placeholder="Enter Payee Email..."
                  onChange={(e) => handlePayeeEmailChange(e, index)}
                />
                <input
                  type="number"
                  value={payee.percentage}
                  placeholder="Enter Payee Percentage..."
                  onChange={(e) => handlePayeePercentageChange(e, index)}
                />
              </div>
            ))}
            <button type="button" className="chill-btn" onClick={addPayee}>
              Add Payee <FaPlus />
            </button>
            <label htmlFor="text">Explicit</label>
            <input
              type="checkbox"
              checked={explicit}
              onChange={(e) => setExplicit(e.target.checked)}
              style={{
                width: "20px",
                marginLeft: "10px",
              }}
            />
          </div>

          <div>
            <label htmlFor="text">
              Audio file <span style={{ color: "#bd0000" }}>*</span>
            </label>
            <input
              type="file"
              name="audio"
              id="formupload"
              onChange={handleFile}
            />
          </div>
          {fileIndicator && (
            <h3 style={{ color: "#00FF00" }}>File selected!</h3>
          )}
          {errorFileIndicator && (
            <h3 style={{ color: "#FF0000" }}>
              File not accepted. Please select a valid file.
            </h3>
          )}
          <button
            type="submit"
            className="btn"
            style={{
              marginBottom: "70px",
              marginTop: "20px",
            }}
          >
            Submit changes for review
          </button>
        </form>
      </section>
    </div>
  );
}

//const forwardedSongForm = forwardRef(SongForm);

export default SongEditForm;
