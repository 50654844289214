import React from "react";
import { FaInstagram } from "react-icons/fa";

const Footer = () => {
  const footerStyle = {
    backgroundColor: "#333",
    color: "#fff",
    textAlign: "center",
    padding: "20px",
    fontSize: "10px",
  };

  const iconStyle = {
    margin: "0 10px",
    fontSize: "24px",
    cursor: "pointer",
    color: "#fff",
  };

  return (
    <footer style={footerStyle}>
      <div>
        <p
          style={{
            fontSize: "14px",
          }}
        >
          Contact us:{" "}
          <a
            href="mailto:support@btdrp.com"
            style={{ color: "#fff", textDecoration: "none" }}
          >
            support@btdrp.com
          </a>
        </p>
      </div>
      <div>
        <a
          href="https://www.instagram.com/btdrpdistro/"
          target="_blank"
          rel="noopener noreferrer"
          style={iconStyle}
        >
          <FaInstagram />
        </a>
      </div>
      <div>
        <p
          style={{
            fontSize: "14px",
          }}
        >
          &copy; {new Date().getFullYear()} Btdrp LLC
        </p>
      </div>
    </footer>
  );
};

export default Footer;
