import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getSavedArtists,
  createSavedArtist,
  reset,
} from "../features/savedArtists/savedArtistSlice";
import { createGoal } from "../features/goals/goalSlice";
import SongForm from "./SongForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import axios from "axios";
import { FaEdit, FaTrashAlt, FaPlus, FaInfoCircle } from "react-icons/fa";
import { format } from "date-fns";

import "react-datepicker/dist/react-datepicker.css";

function GoalEditForm(props) {
  const [text, setText] = useState(props.goal.text);
  const [artist, setArtist] = useState("");
  const [releaseDate, setReleaseDate] = useState(
    new Date(props.goal.releaseDate)
  );
  const [liveDate, setLiveDate] = useState(new Date(props.goal.liveDate));
  //const [releaseDate, setReleaseDate] = useState(null);
  //const [liveDate, setLiveDate] = useState(null);
  const [cLine, setCLine] = useState(props.goal.cLine);
  const [pLine, setPLine] = useState(props.goal.pLine);
  const [labelName, setLabelName] = useState(props.goal.labelName);
  const [numTracks, setNumTracks] = useState(props.goal.numTracks);
  const [upc, setUpc] = useState(props.goal.upc);
  const [status, setStatus] = useState("pending approval by team");
  const [coverArt, setCoverArt] = useState(null);
  const [albumId, setAlbumId] = useState(props.goal.albumId);
  const [primaryGenre, setPrimaryGenre] = useState({
    value: props.goal.primaryGenre,
    label: props.goal.primaryGenre,
  });
  const [secondaryGenre, setSecondaryGenre] = useState({
    value: props.goal.secondaryGenre,
    label: props.goal.secondaryGenre,
  });
  const [accountEmail, setAccountEmail] = useState(props.goal.accountEmail);
  const [accountName, setAccountName] = useState(props.goal.accountName);

  const [artists, setArtists] = useState(props.goal.artists);

  const [writers, setWriters] = useState(props.goal.writers);
  const [producers, setProducers] = useState(props.goal.producers);

  // const [changeLog, setChangeLog] = useState(
  //   props.goal.changeLog ? props.goal.changeLog : []
  // );

  let changeLog = [];

  const [showSong, setShowSong] = useState(false);

  const [triggerSongSubmit, setTriggerSongSubmit] = useState(0);
  const [validSongCount, setValidSongCount] = useState(0);

  //good to go file indicator
  const [fileIndicator, setFileIndicator] = useState(false);

  //error file indicator
  const [errorFileIndicator, setErrorFileIndicator] = useState(false);

  //info hover
  const [infoHover, setInfoHover] = useState(false);

  const genreOptions = [
    { value: "Hip Hop/Rap", label: "Hip Hop/Rap" },
    { value: "Alternative", label: "Alternative" },
    { value: "Alternative / Indie Rock", label: "Alternative / Indie Rock" },
    { value: "Rock", label: "Rock" },
    { value: "Alternative / Punk", label: "Alternative / Punk" },
    { value: "Dance", label: "Dance" },
    { value: "Instrumental", label: "Instrumental" },
    { value: "Pop", label: "Pop" },
    { value: "Latin music / Latin pop", label: "Latin music / Latin pop" },
    { value: "Electronic", label: "Electronic" },
    { value: "Folk", label: "Folk" },
    { value: "Easy Listening", label: "Easy Listening" },
    { value: "Hard Rock & Metal", label: "Hard Rock & Metal" },
    { value: "French Pop", label: "French Pop" },
    { value: "German Pop", label: "German Pop" },
    { value: "Holiday", label: "Holiday" },
    { value: "Jazz", label: "Jazz" },
  ];

  const artistRoleOptions = [
    { value: "Primary", label: "Primary" },
    { value: "Featured", label: "Featured" },
  ];

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { savedArtists, isLoading, isError, message } = useSelector(
    (state) => state.savedArtists
  );

  useEffect(() => {
    console.log("Valid Song Count: ", validSongCount);
  }, [validSongCount]);

  useEffect(() => {
    console.log("artists: ", artists);
  }, [artists]);

  let artistOptions = savedArtists.map((artist) => ({
    value: artist._id,
    label: artist.artistName,
  }));

  useEffect(() => {
    if (isError) {
      console.log(message);
      //localStorage.clear();
    }

    if (!user) {
      /*setTimeout(() => {
        console.log("World!");
      }, 5000);*/
      //navigate("/login");
      console.log("yeetDash");
    }

    if (user) {
      dispatch(getSavedArtists());
      artistOptions = savedArtists.map((artist) => ({
        value: artist._id,
        label: artist.artistName,
      }));
    }

    return () => {
      dispatch(reset());
    };
  }, [user]);

  /*useEffect(() => {
    console.log(albumId);
  }, []);*/

  const incrementCount = () => {
    setValidSongCount(validSongCount + 1);
  };

  const decrementCount = () => {
    setValidSongCount(validSongCount - 1);
  };

  /*const handleImage = (e) => {
    setCoverArt(e.target.files[0]);
  };*/

  function addWriter() {
    setWriters([
      ...writers,
      {
        writerName: "Writer Name",
        legalName: "Legal Name",
      },
    ]);
  }

  function deleteWriter(index) {
    let updatedWriters = [...writers];
    updatedWriters.splice(index, 1);
    setWriters(updatedWriters);
  }

  function handleWriterNameChange(e, index) {
    let updatedWriters = [...writers];
    updatedWriters[index] = {
      ...updatedWriters[index],
      writerName: e.target.value,
    };
    setWriters(updatedWriters);
  }

  function handleWriterLegalNameChange(e, index) {
    let updatedWriters = [...writers];
    updatedWriters[index] = {
      ...updatedWriters[index],
      legalName: e.target.value,
    };
    setWriters(updatedWriters);
  }

  function addProducer() {
    setProducers([
      ...producers,
      {
        producerName: "Producer Name",
      },
    ]);
  }

  function deleteProducer(index) {
    const updatedProducers = [...producers];
    updatedProducers.splice(index, 1);
    setProducers(updatedProducers);
  }

  function handleProducerNameChange(e, index) {
    const updatedProducers = [...producers];
    updatedProducers[index].producerName = e.target.value;
    setProducers(updatedProducers);
  }

  function addArtist() {
    setArtists([
      ...artists,
      {
        artistName: "Artist Name",
        spotifyURI: "New Artist",
        appleId: "New Artist",
        role: "primary",
      },
    ]);
  }

  function deleteArtist(index) {
    const updatedArtists = [...artists];
    updatedArtists.splice(index, 1);
    setArtists(updatedArtists);
  }

  function handleArtistNameChange(e, index) {
    // const updatedArtists = [...artists];
    // updatedArtists[index].artistName = e.target.value;
    // setArtists(updatedArtists);
    const updatedArtists = [...artists];
    updatedArtists[index] = {
      ...updatedArtists[index],
      artistName: e.target.value,
    };
    setArtists(updatedArtists);
  }

  function handleSpotifyURIChange(e, index) {
    // const updatedArtists = [...artists];
    // updatedArtists[index].spotifyURI = e.target.value;
    // setArtists(updatedArtists);
    const updatedArtists = [...artists];
    updatedArtists[index] = {
      ...updatedArtists[index],
      spotifyURI: e.target.value,
    };
    setArtists(updatedArtists);
  }

  function handleAppleIdChange(e, index) {
    // const updatedArtists = [...artists];
    // updatedArtists[index].appleId = e.target.value;
    // setArtists(updatedArtists);
    const updatedArtists = [...artists];
    updatedArtists[index] = {
      ...updatedArtists[index],
      appleId: e.target.value,
    };
    setArtists(updatedArtists);
  }

  function handleRoleChange(selectedOption, index) {
    const updatedArtists = [...artists];
    updatedArtists[index] = {
      ...updatedArtists[index],
      role: selectedOption.value,
    };
    setArtists(updatedArtists);
  }

  function handleArtistSelect(selectedOption, index) {
    const filteredArray = savedArtists.filter((obj) => {
      return obj._id.toString() == selectedOption.value;
    });
    const updatedArtists = [...artists];
    updatedArtists[index].artistName = filteredArray[0].artistName;
    updatedArtists[index].role = filteredArray[0].role;
    updatedArtists[index].spotifyURI = filteredArray[0].spotifyURI;
    updatedArtists[index].appleId = filteredArray[0].appleId;
    setArtists(updatedArtists);
  }

  function saveArtist(art) {
    //const updatedArtists = [...artists];
    //updatedArtists[index].role = selectedOption.value;
    //setArtists(updatedArtists);
    const artistName = art.artistName;
    const role = art.role;
    const spotifyURI = art.spotifyURI;
    const appleId = art.appleId;
    dispatch(
      createSavedArtist({
        artistName,
        role,
        spotifyURI,
        appleId,
      })
    );
    dispatch(reset());
    dispatch(getSavedArtists());
    artistOptions = savedArtists.map((artist) => ({
      value: artist._id,
      label: artist.artistName,
    }));

    toast("Artist info saved!");
  }

  const handleImage = (e) => {
    const file = e.target.files[0];
    //check the file type
    if (file.type !== "image/jpeg" && file.type !== "image/jpg") {
      alert("Please upload a JPG file");
      setCoverArt(null);
      setFileIndicator(false);
      setErrorFileIndicator(true);
      return;
    }
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      const image = new Image();
      image.src = reader.result;
      image.onload = () => {
        const width = image.width;
        const height = image.height;
        const fileType = file.type;
        const fileSize = file.size;
        const validFileType = ["image/jpeg", "image/jpg"].includes(fileType);
        const validDimensions = width === 3000 && height === 3000;
        const validFileSize = fileSize <= 10000000;

        if (!validFileType) {
          alert("Please upload a JPG file");
          setCoverArt(null);
          setFileIndicator(false);
          setErrorFileIndicator(true);
          return;
        }

        if (!validDimensions) {
          alert("Please upload an image with dimensions of 3000x3000");
          setCoverArt(null);
          setFileIndicator(false);
          setErrorFileIndicator(true);
          return;
        }

        if (!validFileSize) {
          alert("Please upload an image that is under 10MB");
          setCoverArt(null);
          setFileIndicator(false);
          setErrorFileIndicator(true);
          return;
        }

        setCoverArt(file);
        setFileIndicator(true);
        setErrorFileIndicator(false);
      };
    };
  };

  const setFileToBase = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setCoverArt(reader.result);
      console.log(reader.result);
    };
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    //setAlbumId(Math.random().toString(36).substr(2, 9));
    //console.log(albumId);

    console.log("text: ", text);
    console.log("releaseDate: ", releaseDate);
    console.log("liveDate: ", liveDate);
    console.log("cLine: ", cLine);
    console.log("pLine: ", pLine);
    console.log("labelName: ", labelName);
    console.log("numTracks: ", numTracks);
    console.log("coverArt: ", coverArt);
    console.log("primaryGenre: ", primaryGenre);
    console.log("artists: ", artists);
    console.log("writers: ", writers);

    if (
      text != "" &&
      releaseDate != null &&
      liveDate != null &&
      cLine != "" &&
      pLine != "" &&
      labelName != "" &&
      numTracks > 0 &&
      // coverArt != null &&
      primaryGenre != null &&
      artists.length > 0 &&
      writers.length > 0
      // releaseDate == null ||
      // liveDate == null ||
      // cLine == "" ||
      // pLine == "" ||
      // labelName == "" ||
      // numTracks < 1 ||
      // // coverArt == null ||
      // primaryGenre == null ||
      // artists.length == 0 ||
      // writers.length == 0
    ) {
      //setTriggerSongSubmit(triggerSongSubmit + 1);

      let imageUrl = props.goal.imageUrl;

      if (coverArt != null) {
        // get secure url from our server
        const { url } = await fetch("/s3Url").then((res) => res.json());
        console.log(url);

        // post the image direclty to the s3 bucket
        await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "Image/jpeg",
          },
          body: coverArt,
        });

        imageUrl = url.split("?")[0];
      }

      const primary = primaryGenre.value;
      let secondary = "None";
      if (secondaryGenre.value) {
        secondary = secondaryGenre.value;
      }

      //figure out which fields have been changed, and change the status to "changes added: " plus any fields that have been changed separated by commas
      let newStatus = "pending approval by team";
      console.log(
        releaseDate.getTime() === new Date(props.goal.releaseDate).getTime()
      );
      if (
        text != props.goal.text ||
        releaseDate.getTime() != new Date(props.goal.releaseDate).getTime() ||
        liveDate.getTime() != new Date(props.goal.liveDate).getTime() ||
        cLine != props.goal.cLine ||
        pLine != props.goal.pLine ||
        labelName != props.goal.labelName ||
        numTracks != props.goal.numTracks ||
        upc != props.goal.upc ||
        imageUrl != props.goal.imageUrl ||
        primary != props.goal.primaryGenre ||
        secondary != props.goal.secondaryGenre ||
        accountEmail != props.goal.accountEmail ||
        accountName != props.goal.accountName ||
        artists != props.goal.artists ||
        writers != props.goal.writers ||
        producers != props.goal.producers
      ) {
        newStatus = "changes added: ";
        if (text != props.goal.text) {
          newStatus += "title, ";
          changeLog.push(
            "Title changed from " + props.goal.text + " to " + text
          );
        }
        if (
          releaseDate.getTime() != new Date(props.goal.releaseDate).getTime()
        ) {
          newStatus += "release date, ";
          changeLog.push(
            "Release date changed from " +
              props.goal.releaseDate +
              " to " +
              releaseDate
          );
          // console.log(releaseDate);
          // console.log(props.goal.releaseDate);
          // console.log(new Date(props.goal.releaseDate));
        }
        if (liveDate.getTime() != new Date(props.goal.liveDate).getTime()) {
          newStatus += "live date, ";
          changeLog.push(
            "Live date changed from " + props.goal.liveDate + " to " + liveDate
          );
        }
        if (cLine != props.goal.cLine) {
          newStatus += "c line, ";
          changeLog.push(
            "C Line changed from " + props.goal.cLine + " to " + cLine
          );
        }
        if (pLine != props.goal.pLine) {
          newStatus += "p line, ";
          changeLog.push(
            "P Line changed from " + props.goal.pLine + " to " + pLine
          );
        }
        if (labelName != props.goal.labelName) {
          newStatus += "label name, ";
          changeLog.push(
            "Label name changed from " +
              props.goal.labelName +
              " to " +
              labelName
          );
        }
        if (numTracks != props.goal.numTracks) {
          newStatus += "number of tracks, ";
          changeLog.push(
            "Number of tracks changed from " +
              props.goal.numTracks +
              " to " +
              numTracks
          );
        }
        if (upc != props.goal.upc) {
          newStatus += "upc, ";
          changeLog.push("UPC changed from " + props.goal.upc + " to " + upc);
        }
        if (imageUrl != props.goal.imageUrl) {
          newStatus += "cover art, ";
          changeLog.push("Cover art changed");
        }
        if (primary != props.goal.primaryGenre) {
          newStatus += "primary genre, ";
          changeLog.push(
            "Primary genre changed from " +
              props.goal.primaryGenre +
              " to " +
              primary
          );
        }
        if (secondary != props.goal.secondaryGenre) {
          newStatus += "secondary genre, ";
          changeLog.push(
            "Secondary genre changed from " +
              props.goal.secondaryGenre +
              " to " +
              secondary
          );
        }
        if (artists != props.goal.artists) {
          newStatus += "artists, ";
          // loop through artists and check if any have changed. If so, note the specific field that changed
          for (let i = 0; i < props.goal.artists.length; i++) {
            if (artists[i] && artists[i].artistName != props.goal.artists[i].artistName) {
              changeLog.push(
                "Artist " +
                  (i + 1) +
                  " name changed from " +
                  props.goal.artists[i].artistName +
                  " to " +
                  artists[i].artistName
              );
            }
            if (artists[i] && artists[i].role != props.goal.artists[i].role) {
              changeLog.push(
                "Artist " +
                  (i + 1) +
                  " role changed from " +
                  props.goal.artists[i].role +
                  " to " +
                  artists[i].role
              );
            }
            if (artists[i] && artists[i].spotifyURI != props.goal.artists[i].spotifyURI) {
              changeLog.push(
                "Artist " +
                  (i + 1) +
                  " Spotify URI changed from " +
                  props.goal.artists[i].spotifyURI +
                  " to " +
                  artists[i].spotifyURI
              );
            }
            if (artists[i] && artists[i].appleId != props.goal.artists[i].appleId) {
              changeLog.push(
                "Artist " +
                  (i + 1) +
                  " Apple ID changed from " +
                  props.goal.artists[i].appleId +
                  " to " +
                  artists[i].appleId
              );
            }
          }
        }
        if (writers != props.goal.writers) {
          newStatus += "writers, ";
          // loop through writers and check if any have changed. If so, note the specific field that changed
          for (let i = 0; i < props.goal.writers.length; i++) {
            if (writers[i] && writers[i].writerName != props.goal.writers[i].writerName) {
              changeLog.push(
                "Writer " +
                  (i + 1) +
                  " name changed from " +
                  props.goal.writers[i].writerName +
                  " to " +
                  writers[i].writerName
              );
            }
            if (writers[i] && writers[i].legalName != props.goal.writers[i].legalName) {
              changeLog.push(
                "Writer " +
                  (i + 1) +
                  " legal name changed from " +
                  props.goal.writers[i].legalName +
                  " to " +
                  writers[i].legalName
              );
            }
          }
        }
        if (producers != props.goal.producers) {
          newStatus += "producers, ";
          // loop through producers and check if any have changed. If so, note the specific field that changed
          for (let i = 0; i < props.goal.producers.length; i++) {
            if (
              producers[i] && producers[i].producerName != props.goal.producers[i].producerName
            ) {
              changeLog.push(
                "Producer " +
                  (i + 1) +
                  " name changed from " +
                  props.goal.producers[i].producerName +
                  " to " +
                  producers[i].producerName
              );
            }
          }
        }
        newStatus = newStatus.slice(0, -2);
      }

      if(props.goal.isDistributed == null || props.goal.isDistributed == false){
        newStatus = "pending approval by team";
      }

      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      axios.put(
        "/api/goals/" + props.goal._id,
        {
          text: text,
          releaseDate: format(releaseDate, 'yyyy-MM-dd'),
          liveDate: format(liveDate, 'yyyy-MM-dd'),
          cLine: cLine,
          pLine: pLine,
          labelName: labelName,
          numTracks: numTracks,
          upc: upc,
          status: newStatus,
          imageUrl: imageUrl,
          albumId: albumId,
          primary: primary,
          secondary: secondary,
          accountEmail: accountEmail,
          accountName: accountName,
          artists: artists,
          writers: writers,
          producers: producers,
          changeLog: props.goal.changeLog
            ? [...props.goal.changeLog, ...changeLog]
            : changeLog,
        },
        config
      );

      /*dispatch(
        createGoal({
          text,
          releaseDate,
          liveDate,
          cLine,
          pLine,
          labelName,
          numTracks,
          upc,
          status,
          imageUrl,
          albumId,
          primary,
          secondary,
          accountEmail,
          accountName,
          artists,
          writers,
          producers,
        })
      );*/
      toast(
        "Album sent for review. You can view your album status by clicking on My Albums"
      );
      window.location.reload(false);
    } else {
      toast("One or more song fields left empty.");
    }
  };

  var songs = [];
  for (var i = 0; i < numTracks; i++) {
    songs.push(
      <div key={i}>
        <SongForm
          num={i + 1}
          trigger={triggerSongSubmit}
          increment={incrementCount}
          decrement={decrementCount}
          album={albumId}
          artists={artists}
          writers={writers}
          producers={producers}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        overflow: "scroll",
        height: "100vh",
      }}
    >
      <ToastContainer />
      {!showSong && (
        <section className="form">
          <h3>Album details</h3>
          <form onSubmit={onSubmit}>
            <div className="form-container">
              <div className="form-group">
                <label htmlFor="text">
                  Cover art <span style={{ color: "#bd0000" }}>*</span>
                </label>
                <input
                  type="file"
                  name="image"
                  id="formupload"
                  onChange={handleImage}
                />
                <p style={{ fontSize: "16px" }}>
                  Artworks Requirements:
                  <br />
                  • JPEG / JPG files only
                  <br />• Must be 3000px x 3000px
                  <br />• Max file size 10MB
                </p>
                {fileIndicator && (
                  <h3 style={{ color: "#00FF00" }}>File selected!</h3>
                )}
                {errorFileIndicator && (
                  <h3 style={{ color: "#FF0000" }}>
                    File not accepted. Please select a valid file.
                  </h3>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="text">
                  Title <span style={{ color: "#bd0000" }}>*</span>
                </label>
                <input
                  type="text"
                  name="text"
                  id="text"
                  value={text}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                />
                <label htmlFor="text">
                  Primary Genre <span style={{ color: "#bd0000" }}>*</span>
                </label>
                <Select
                  defaultValue={primaryGenre}
                  onChange={setPrimaryGenre}
                  options={genreOptions}
                />
                <div style={{ paddingBottom: "20px" }}></div>

                <label htmlFor="text">
                  Release Date <span style={{ color: "#bd0000" }}>*</span>
                </label>
                {/*<input
                  type="text"
                  name="releaseDate"
                  id="releaseDate"
                  value={releaseDate}
                  onChange={(e) => {
                    setReleaseDate(e.target.value);
                  }}
                />*/}
                <DatePicker
                  selected={releaseDate}
                  onChange={(date) => setReleaseDate(date)}
                />

                <label htmlFor="text">
                  ℗ Line <span style={{ color: "#bd0000" }}>*</span>
                </label>
                <input
                  type="text"
                  name="pLine"
                  id="pLine"
                  value={pLine}
                  onChange={(e) => {
                    setPLine(e.target.value);
                  }}
                />
                <label htmlFor="text">
                  Label Name <span style={{ color: "#bd0000" }}>*</span>
                </label>
                <input
                  type="text"
                  name="labelName"
                  id="labelName"
                  value={labelName}
                  onChange={(e) => {
                    setLabelName(e.target.value);
                  }}
                />
                {/*<img src={coverArt} style={{ width: "100px" }} />*/}
                <label htmlFor="text">
                  Number of Tracks <span style={{ color: "#bd0000" }}>*</span>
                </label>
                <input
                  type="text"
                  name="numTracks"
                  id="numTracks"
                  value={numTracks}
                  onChange={(e) => {
                    setNumTracks(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="text">UPC</label>
                <input
                  type="text"
                  name="upc"
                  id="upc"
                  value={upc}
                  onChange={(e) => {
                    setUpc(e.target.value);
                  }}
                />
                <label htmlFor="text">Secondary Genre</label>
                <Select
                  defaultValue={secondaryGenre}
                  onChange={setSecondaryGenre}
                  options={genreOptions}
                />
                <div style={{ paddingBottom: "20px" }}></div>
                <label htmlFor="text">
                  Live Date <span style={{ color: "#bd0000" }}>*</span>
                </label>
                {/*<input
                  type="text"
                  name="liveDate"
                  id="liveDate"
                  value={liveDate}
                  onChange={(e) => {
                    setLiveDate(e.target.value);
                  }}
                />*/}
                <DatePicker
                  selected={liveDate}
                  onChange={(date) => setLiveDate(date)}
                />
                <label htmlFor="text">
                  © Line <span style={{ color: "#bd0000" }}>*</span>
                </label>
                <input
                  type="text"
                  name="cLine"
                  id="cLine"
                  value={cLine}
                  onChange={(e) => {
                    setCLine(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="text">
                  Artist(s) <span style={{ color: "#bd0000" }}>*</span>
                </label>
                {artists.map((art, index) => (
                  <div
                    key={index}
                    className="goal"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <label>{art.artistName}</label>
                    <div>
                      <Popup
                        nested
                        trigger={
                          <button
                            type="button"
                            style={{
                              fontSize: "20px",
                              background: "none",
                              border: "none",
                              paddingLeft: "5px",
                              paddingRight: "5px",
                              cursor: "pointer",
                            }}
                          >
                            {" "}
                            <FaEdit />
                          </button>
                        }
                        position="center"
                        className="my-popup"
                        modal
                      >
                        <div className="form-group">
                          {savedArtists && savedArtists.length > 0 && (
                            <div>
                              <label>Select saved artist:</label>
                              <Select
                                defaultValue={null}
                                onChange={(selected) => {
                                  handleArtistSelect(selected, index);
                                }}
                                options={artistOptions}
                              />
                            </div>
                          )}
                          <label htmlFor="text">
                            Role <span style={{ color: "#bd0000" }}>*</span>
                          </label>
                          <Select
                            defaultValue={art.role}
                            onChange={(selectedValue) => {
                              handleRoleChange(selectedValue, index);
                            }}
                            options={artistRoleOptions}
                          />
                          <div style={{ paddingBottom: "20px" }}></div>
                          <label>
                            Artist Name:{" "}
                            <span style={{ color: "#bd0000" }}>*</span>
                          </label>
                          <input
                            type="text"
                            value={art.artistName}
                            onChange={(e) => handleArtistNameChange(e, index)}
                          />

                          <label>
                            Spotify URI (e.g.
                            spotify:artist:6kw4tIoctSJkUveo6rkW9u):{" "}
                            {art.spotifyURI != "New Artist" &&
                              art.spotifyURI.length != 37 && (
                                <>
                                  <br />
                                  <span style={{ color: "red" }}>
                                    Please enter a valid Spotify URI or "New
                                    Artist"
                                  </span>
                                </>
                              )}
                          </label>
                          <input
                            type="text"
                            value={art.spotifyURI}
                            onChange={(e) => handleSpotifyURIChange(e, index)}
                          />
                          <label>
                            Apple Id (e.g. 1523332292, the numbers in your Apple
                            Music artist page):
                          </label>
                          <input
                            type="text"
                            value={art.appleId}
                            onChange={(e) => handleAppleIdChange(e, index)}
                          />
                        </div>
                        <button
                          type="button"
                          className="chill-btn"
                          onClick={() => saveArtist(art)}
                        >
                          Save Artist
                        </button>
                      </Popup>
                      <button
                        type="button"
                        onClick={() => deleteArtist(index)}
                        style={{
                          fontSize: "20px",
                          background: "none",
                          border: "none",
                          color: "#bd0000",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <FaTrashAlt />
                      </button>
                    </div>
                  </div>
                ))}
                <button type="button" className="chill-btn" onClick={addArtist}>
                  Add Artist <FaPlus />
                </button>
                <h3
                  htmlFor="text"
                  style={{ marginTop: "20px", textAlign: "left" }}
                >
                  Writer(s) <span style={{ color: "#bd0000" }}>*</span>
                </h3>
                {writers.map((writer, index) => (
                  <div
                    key={index}
                    //className="goal"
                    //style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <label>
                        Writer {index + 1} Name
                        <button
                          type="button"
                          onClick={() => deleteWriter(index)}
                          style={{
                            fontSize: "20px",
                            background: "none",
                            border: "none",
                            color: "#bd0000",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            cursor: "pointer",
                          }}
                        >
                          <FaTrashAlt />
                        </button>
                        {writer.writerName.search(" ") == -1 && (
                          <>
                            <br />
                            <span style={{ color: "red" }}>
                              Please enter a first and last name for the writer.
                            </span>
                          </>
                        )}
                      </label>
                      <input
                        type="text"
                        value={writer.writerName}
                        onChange={(e) => handleWriterNameChange(e, index)}
                      />

                      <label>
                        Writer {index + 1} Legal Name{" "}
                        <span
                          // title="Required for distribution. Will not be shared."
                          style={{}}
                          onMouseEnter={() => setInfoHover(true)}
                          onMouseLeave={() => setInfoHover(false)}
                        >
                          <FaInfoCircle />
                        </span>
                        {infoHover && (
                          <div
                            style={{
                              position: "absolute",
                              zIndex: "100",
                              background: "#f9f9f9",
                              padding: "10px",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              width: "200px",
                              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            Required for distribution. Will not be shared
                            publicly. Might be the same as the writer name.
                          </div>
                        )}
                      </label>
                      <input
                        type="text"
                        value={writer.legalName}
                        onChange={(e) => handleWriterLegalNameChange(e, index)}
                      />
                    </div>
                  </div>
                ))}
                <button type="button" className="chill-btn" onClick={addWriter}>
                  Add Writer <FaPlus />
                </button>
                <h3
                  htmlFor="text"
                  style={{ marginTop: "20px", textAlign: "left" }}
                >
                  Producer(s)
                </h3>
                {producers.map((producer, index) => (
                  <div
                    key={index}
                    //className="goal"
                    //style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <label>
                        Producer {index + 1}{" "}
                        <button
                          type="button"
                          onClick={() => deleteProducer(index)}
                          style={{
                            fontSize: "20px",
                            background: "none",
                            border: "none",
                            color: "#bd0000",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            cursor: "pointer",
                          }}
                        >
                          <FaTrashAlt />
                        </button>
                      </label>
                      <input
                        type="text"
                        value={producer.producerName}
                        onChange={(e) => handleProducerNameChange(e, index)}
                      />
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  className="chill-btn"
                  onClick={addProducer}
                >
                  Add Producer <FaPlus />
                </button>
              </div>
            </div>
            <div className="form-group">
              <button
                className="btn btn-submit"
                type="submit"
                style={{ marginTop: "20px" }}
              >
                Submit Revisions for Review ↦
              </button>
            </div>
          </form>
        </section>
      )}
      {showSong && (
        <section>
          <h3>Song details</h3>
          {songs}
          <button
            className="btn btn-submit"
            onClick={async () => {
              if (validSongCount == numTracks) {
                setTriggerSongSubmit(triggerSongSubmit + 1);

                // get secure url from our server
                const { url } = await fetch("/s3Url").then((res) => res.json());
                console.log(url);

                // post the image direclty to the s3 bucket
                await fetch(url, {
                  method: "PUT",
                  headers: {
                    "Content-Type": "Image/jpeg",
                  },
                  body: coverArt,
                });

                const imageUrl = url.split("?")[0];

                const primary = primaryGenre.value;
                let secondary = "None";
                if (secondaryGenre.value) {
                  secondary = secondaryGenre.value;
                }

                dispatch(
                  createGoal({
                    text,
                    releaseDate,
                    liveDate,
                    cLine,
                    pLine,
                    labelName,
                    numTracks,
                    upc,
                    status: "pending approval by team",
                    imageUrl,
                    albumId,
                    primary,
                    secondary,
                    accountEmail,
                    accountName,
                    artists,
                    writers,
                    producers,
                  })
                );
                toast(
                  "Album sent for review. You can view your album status by clicking on My Albums"
                );
              } else {
                toast("One or more song fields left empty.");
              }
              //setTriggerSongSubmit(triggerSongSubmit + 1);
            }}
          >
            Submit album for review
          </button>
        </section>
      )}
    </div>
  );
}

export default GoalEditForm;
